'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitDevicesParametersCtrl

 # @description

###
class UnitDevicesParametersCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitsManager
    DeviceTypeManager
    $log
    $q
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = UnitsManager.getDevicesForm()
      @load = load
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    load = ->
      DeviceTypeManager.getListPromise().then (responseDeviceTypes) =>
        deviceTypes = []
        for k, v of responseDeviceTypes.results
          deviceTypes.push(v)
          
        @deviceTypeProfiles = []
        angular.forEach deviceTypes, (devType) =>
          angular.forEach devType.profiles, (profile) =>
            @deviceTypeProfiles.push {code: devType.code + '_' + profile.code, parameters: profile.parameters}
        UnitsManager.getDevices(@id)
          .then (data) =>
            @items = _.filter data, (item) -> !item.deletedAt?
            angular.forEach @items, (item) ->
              item.checked = false
            @progress = false
            @form = {}

    @parametersToShow = []
    @selectedItems = []

    @getProfileParameters = (typeProfile) =>
      foundParameters = @deviceTypeProfiles.filter (devTypeProfile) ->
        return devTypeProfile.code == typeProfile
      if (angular.isArray foundParameters) && (foundParameters.length > 0)
        return foundParameters[0].parameters
      return []

    @deviceParameters = =>
      @selectedItems = []
      tempParams = []
      angular.forEach @items, (item) =>
        if item.checked
          @selectedItems.push item
          angular.extend tempParams, @getProfileParameters(item.device.deviceType + '_' + item.deviceTypeProfile)
      @parametersToShow = tempParams

    @getParameterById = (device, param) ->
      deviceParameters = @getProfileParameters(device.device.deviceType + '_' + device.deviceTypeProfile)
      deviceContainsParameter = deviceParameters.filter (devParam) ->
        return devParam.code == param.code
      returnValue = {parameter: param, value: null}
      if (angular.isArray deviceContainsParameter) && (deviceContainsParameter.length > 0)
        returnValue.value = device.parameters[param.code]
      else
        returnValue.value = 'n/a'
        returnValue.isDisabled = true
      return returnValue


    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'

    @saveDeviceParameters = =>
      angular.forEach @selectedItems, (device) =>
        parameters = {}
        angular.forEach @parametersToShow, (inputParameter) =>
          if !(@getParameterById(device, inputParameter).isDisabled)
            if inputParameter.newValue
              switch inputParameter.type
                when 'integer'
                  parameters[inputParameter.code] = parseInt(inputParameter.newValue, 10)
                when 'string'
                  parameters[inputParameter.code] = inputParameter.newValue
                else
                  parameters[inputParameter.code] = inputParameter.newValue
        if Object.keys(parameters).length > 0
          UnitsManager.saveDeviceParameters id, device.id, parameters

      $mdDialog.cancel()

    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitDevicesParametersCtrl', UnitDevicesParametersCtrl
